



























































import { Vue, Component } from 'vue-property-decorator'
import pageTitle from '@/components/common/pageTitle.vue'
import UploadImage from '@/components/common/UploadImage/index.vue'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import detect from '@/utils/detectImage'
@Component({
  components: { pageTitle, UploadImage, FeatureImg }
})
export default class FaceContrast extends Vue {
  private statHeight: string | number = '750'
  position = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  }
  leftImage: string | null = null
  rightImage: string | null = null
  leftRect = null
  rightRect = null
  leftError = ''
  rightError = ''
  leftLoading = false
  rightLoading = false
  level = null
  mounted() {
    this.calTableHeight()
    window.addEventListener('resize', this.calTableHeight)
  }
  deactivated() {
    window.removeEventListener('resize', this.calTableHeight)
  }
  private calTableHeight() {
    this.$nextTick(() => {
      this.statHeight = window.innerHeight - 48
    })
  }
  async getPersonCompare() {
    const image1 = this.getImage(this.leftImage)
    const image2 = this.getImage(this.rightImage)
    const { data } = await this.$api.v2.personsCompare({
      sourceImage: image1,
      targetImage: image2
    })
    if (data.code === 0) {
      this.level = data.data.level
    } else {
      this.level = null
    }
  }
  async searchImgLeft(val: string) {
    this.leftLoading = true
    await this.searchImg(val, 'left')
    this.changeImage()
  }
  async searchImgRight(val: string) {
    this.rightLoading = true
    await this.searchImg(val, 'right')
    this.changeImage()
  }
  changeImage() {
    if (this.rightImage && this.leftImage) {
      this.getPersonCompare()
    } else {
      this.level = null
    }
  }
  async searchImg(val: string, type: string) {
    const resp = await detect.getPersonDetect(val, false)
    if (resp) {
      if (type === 'left') {
        this.leftImage = resp.error ? null : val
        this.leftRect = resp.image
        this.leftError = resp.error
        this.leftLoading = false
      } else {
        this.rightImage = resp.error ? null : val
        this.rightRect = resp.image
        this.rightError = resp.error
        this.rightLoading = false
      }
    }
  }
  getImage(val: any) {
    let CreateImg
    if (val.indexOf('http://') != -1 || val.indexOf('https://') != -1) {
      CreateImg = { imageUrl: val }
    } else {
      CreateImg = { imageData: val.split(',')[1] }
    }
    return CreateImg
  }
}
